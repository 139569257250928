import React, { Component } from "react";
import { Button, Form, Container } from "react-bootstrap";
import "./Login.css";
import FloatingLabel from 'react-styled-floating-label';
import Slide from 'react-reveal/Slide';
import Pulse from 'react-reveal/Pulse';




export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: ""
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
  }

  render() {
    return (
      <Slide left>
        <Container id="Login">
        <Pulse>
          <Form>
              <Form.Group controlId="formBasicEmail">
                  <FloatingLabel text="Email">
                    <Form.Control type="email" placeholder="Enter email" />
                  </FloatingLabel>
                  <Form.Text className="text-muted">
                  </Form.Text>
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                  <FloatingLabel text="Password">
                    <Form.Control type="password" placeholder="Password" />
                  </FloatingLabel>
              </Form.Group>
              
              <Button variant="primary" type="submit" block>
                  Submit
              </Button>
          </Form>
        </Pulse>
        </Container>
      </Slide>
    );
  }
}
