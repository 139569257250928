import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav } from 'react-bootstrap';
import './App.css';
import Routes from "./Routes";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Logo from './img/clementkelly-logo.png';



class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOnLogin: false
    };
  }

  userOnLogin = login => {
    this.setState({isOnLogin: login});
  }

  handleClick = event => {
    this.setState({isOnLogin: true});
  }

  handleClickFullNav = event => {
    this.setState({isOnLogin: false});
  }
  
  render() {

    const childProps = {
      
    }

    return(
      <div>
          <Navbar className="shadow" id="navbar" sticky="top" expand="lg" variant="dark" collapseOnSelect>
            <Navbar.Brand>
              <Link to="/" onClick={this.handleClickFullNav}>
              <div className="text-center">
                <img src={Logo} alt="clementkelly-logo"
                  className="logo"
                  width="100"
                />
              </div>
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                  <Nav variant="pills" activeKey="/" className="ml-auto">
                    <Nav.Item>
                      <Nav.Link>
                        <AnchorLink offset='50' href="#ComponentOneDiv">
                          About
                        </AnchorLink>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link>
                        <AnchorLink offset='50' href="#ComponentTwoDiv">
                            Projects
                        </AnchorLink>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link>
                        <AnchorLink href="#ComponentThreeDiv">
                          Contact
                        </AnchorLink>
                      </Nav.Link>
                    </Nav.Item>
                    </Nav>

              </Navbar.Collapse>
          </Navbar>
          <Routes childProps={childProps} />
      </div>
    );
  }
}


export default App;
