import React from 'react';
import { Container, Row, Col, Image, Accordion, Card, Button } from 'react-bootstrap';
import './ComponentOne.css';
import ProfileImage from '../img/clementkelly-profile.jpg';

export default class ComponentOne extends React.Component {

    render() {
        return(
        <div id="ComponentOneDiv">
        <div id="ComponentOne">
           <Container fluid className="text-center">
                <h1 className="component-title mb-5">ABOUT</h1>
           </Container>
           <Container>
               <Row>
                   <Col className="text-center" sm="3">
                        <Image className="mb-3" src={ProfileImage} width="100px" roundedCircle />
                   </Col>
                   <Col className="text-left">
                        <h1>Clement Kelly</h1>
                        <h2 className="mt-3 mb-4">Front End Developer</h2>
                        <p className="text-justify mb-4">The rapid evolution of technology is constantly changing the way we explore and search for services in today's day and age. I am passionate about helping organisations adopt and implement best practices when it comes to Front End Development. My goal is to offer my client's customers an immersive user experience that leads to rave reviews and repeat business.</p>
                        <Accordion className="accordion">
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                &#43; Who Am I?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>I am a Front End Developer specialised in React JS and with a keen interest in cloud computing. I am always on the lookout for contemporary web design layouts, disruptive technologies and the latest innovation trends.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                &#43; What I'm Really Good At?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                <Card.Body>My approach to understanding my client's philosophy and market positioning is what I'm really good at and is the first step I undertake prior to developing a solution for a client.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                &#43; What Are My Hobbies?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                <Card.Body>Besides writing beautiful code and designing amazing online experiences I have a passion for sports photography.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                   </Col>
               </Row>
           </Container>
        </div>
        </div>
        );
    }
    
}

