import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './Hero.css';
import HeroImage from '../img/clementkelly-hero.jpg';
import LazyHero from 'react-lazy-hero';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export default class Hero extends React.Component {

    render() {
        return(
        <div className="black-overlay">
            <LazyHero
            imageSrc={HeroImage}
            minHeight='100vh'
            opacity="0.5"
            color="#000"
            transitionDuration="600"
            >
            <Container id="lazy-hero-text-overlay">
                <h1 className="smaller mb-4">G'DAY! THIS IS THE HOME OF</h1>
                <hr></hr>
                <h1 className="bigger">CLEMENT KELLY</h1>
                <hr></hr>
                <h1 className="smaller mt-5">FRONT END DEVELOPER</h1>
            <Row>
                <Col sm>
                    <AnchorLink offset="50" href="#ComponentOneDiv">
                        <Button className="read-more">Read More</Button>
                    </AnchorLink>
                </Col>
            </Row>
            </Container>
            </LazyHero>
        </div>
        );
    }
    
}

