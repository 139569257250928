import React from 'react';
import { Container } from 'react-bootstrap';
import './Footer.css';

export default class Footer extends React.Component {

    render() {
        return(
        <div id="footer">
            <Container fluid className="text-center">
                <p>Clement Kelly &copy; 2019</p>
                <p>Another Serverless Application</p>
            </Container>  
        </div>
        );
    }
    
}
