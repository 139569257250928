import React, { Component } from 'react';
import './Home.css';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import ComponentOne from '../components/ComponentOne';
import ComponentTwo from '../components/ComponentTwo';
import ComponentThree from '../components/ComponentThree';

export default class Home extends Component {
    render() {
        return(
            <div>
                <Hero />
                <ComponentOne />
                <ComponentTwo />
                <ComponentThree />
                <Footer />
            </div>
        );
    }
}