import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './ComponentThree.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faTools, faCommentsDollar, faChartLine, faEnvelope, } from '@fortawesome/free-solid-svg-icons';

export default class ComponentThree extends React.Component {

    render() {
        return(
        <div id="ComponentThreeDiv">
            <div id="ComponentThree">
           <Container fluid className="text-center">
                <h1 className="component-title">CONTACT</h1>
           </Container>
           <Container className="big-margin-bottom text-center">
                <Row>
                    <Col>
                        <a href="mailto:info@clementkelly.com">
                        <Container>
                            <FontAwesomeIcon className="fa-2x" icon={faEnvelope} />
                            <h1>Email Me</h1>
                        </Container>
                        </a>
                    </Col>
                    <Col>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/clement-kelly/">
                        <Container>
                            <FontAwesomeIcon className="fa-2x" icon={faLinkedin} />
                            <h1>Let's Connect</h1>
                        </Container>
                        </a>
                    </Col>
                </Row>
           </Container>
           </div>
        </div>
        );
    }
    
}

