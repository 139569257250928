import React, { Component } from 'react';
import { Form, Container, Button, Toast }  from 'react-bootstrap';
import './Signup.css';
import FloatingLabel from 'react-styled-floating-label';
import Slide from 'react-reveal/Slide';
import Pulse from 'react-reveal/Pulse';

export default class Signup extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        isLoading: false,
        email: "",
        password: "",
        confirmPassword: "",
        confirmationCode: "",
        newUser: null
      };
    }
  
    validateForm() {
      return (
        this.state.email.length > 0 &&
        this.state.password.length > 0 &&
        this.state.password === this.state.confirmPassword
      );
    }
  
    validateConfirmationForm() {
      return this.state.confirmationCode.length > 0;
    }
  
    handleChange = event => {
      this.setState({
        [event.target.id]: event.target.value
      });
    }
  
    handleSubmit = async event => {
      event.preventDefault();
  
      this.setState({ isLoading: true });
  
      this.setState({ newUser: "test" });
  
      this.setState({ isLoading: false });
    }
  
    handleConfirmationSubmit = async event => {
      event.preventDefault();
  
      this.setState({ isLoading: true });
    }
  
    renderConfirmationForm() {
      return (
        <Form onSubmit={this.handleConfirmationSubmit}>
          <Form.Group controlId="confirmationCode" bsSize="large">
            <Form.Label>Confirmation Code</Form.Label>
            <Form.Control
              autoFocus
              type="tel"
              value={this.state.confirmationCode}
              onChange={this.handleChange}
            />
            <Toast>Please check your email for the code.</Toast>
          </Form.Group>
          <Button
            block
            bsSize="large"
            disabled={!this.validateConfirmationForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Verify"
            loadingText="Verifying…"
          />
        </Form>
      );
    }
  
    renderForm() {
      return (
        <Slide right>
        <Container id="Signup">
        <Pulse>
        <Form>
            <Form.Group controlId="formBasicEmail">
                <FloatingLabel text="Email">
                    <Form.Control type="email" placeholder="Email" />
                </FloatingLabel>
                <Form.Text className="text-muted">
                </Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
                <FloatingLabel text="Password">
                    <Form.Control type="password" placeholder="Password" />
                </FloatingLabel>
                <Form.Text className="text-muted">
                </Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicConfPassword">
                <FloatingLabel text="Confirm Password">
                    <Form.Control type="password" placeholder="Confirm Password" />
                </FloatingLabel>
                <Form.Text className="text-muted">
                </Form.Text>
            </Form.Group>

            <Button variant="primary" type="submit" block>
                Submit
            </Button>
        </Form>
        </Pulse>
    </Container>
    </Slide>

      );
    }
  
    render() {
      return (
        <div className="Signup">
          {this.state.newUser === null
            ? this.renderForm()
            : this.renderConfirmationForm()}
        </div>
      );
    }
  }