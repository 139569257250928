import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import './ComponentTwo.css';
import CopperChimneyLogo from '../img/copperchimney-logo.png';
import LaylaLogo from '../img/layla-logo.png';
import YellerooLogo from '../img/yelleroo_logo.png';
import OdasitiLogo from '../img/odasiti-logo-white.png';
import FractionPayLogo from '../img/fractionpay-logo.png';
import SprintingAcademyLogo from '../img/sprintingacademy-logo.png';

export default class ComponentTwo extends React.Component {

    render() {
        return(
        <div id="ComponentTwoDiv" >
           <Container id="ComponentTwo" fluid className="text-center">
                <h1 className="component-title white mb-5">ONGOING PROJECTS</h1>
           </Container>
           <Container id="project-cols" className="text-center">
               <Row className="big-margin-bottom">
                   {/*
                   <Col sm>
                    <img className="img-fluid mb-5 border-on-hover" src={CopperChimneyLogo} width="130px" />
                   </Col>

                   <Col sm>
                   <img className="img-fluid" src={LaylaLogo} width="100px" />
                   </Col>
                   */}
                    <Container>
                        <Row className="mb-5">
                            <Col className="mb-5" sm>
                            <a target="_blank" rel="noopener noreferrer" href="https://yelleroo.com/">
                                <Container className="project1">
                                <div className="p-5">
                                    <img src={YellerooLogo} width="70px" />
                                </div>
                                <div>
                                    <h1 className="">Yelleroo</h1>
                                    <p className="text-center mb-5">
                                    Building Agile Ventures
                                    </p>
                                </div>
                                </Container>
                            </a>
                            </Col>

                            <Col  sm>
                            <a target="_blank" rel="noopener noreferrer" href="https://odasiti.com/">
                                <Container className="project2">
                                <div className="p-5">
                                    <img src={OdasitiLogo} width="70px" />
                                </div>
                                <div>
                                    <h1>Odasiti</h1>
                                    <p className="text-center mb-5">
                                    Find love the way you live
                                    </p>
                                </div>
                                </Container>
                            </a>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-5" sm>
                            <a target="_blank" rel="noopener noreferrer" href="https://fractionpay.com/">
                            <Container className="project3">
                                <div className="p-5">          
                                    <img className=""  src={FractionPayLogo} width="70px" />
                                </div>
                                <div>
                                    <h1>FractionPay</h1>
                                    <p className="text-center mb-5">
                                    A New Way to Pay
                                    </p>
                                </div> 
                            </Container>
                            </a>   
                            </Col>

                            <Col sm>
                            <a target="_blank" rel="noopener noreferrer" href="https://sprintingacademy.com/">
                            <Container className="project4 mb-5">
                                <div className="p-5">
                                    <img src={SprintingAcademyLogo} width="70px" />
                                </div>
                                <div>
                                    <h1>Sprinting Academy</h1>
                                    <p className="text-center mb-5">
                                    Make It Count
                                    </p>
                                </div>
                            </Container>
                            </a>
                            </Col>
                        </Row>
                   </Container>
               </Row>
           </Container>
        </div>
        );
    }
    
}

